export default class Template {
	template: HTMLTemplateElement | null = null;

	#fragment: DocumentFragment | null = null;

	constructor( selector: string, scope: Element = document.body ) {
		const template = scope.querySelector( `template${selector}` );
		if ( template instanceof HTMLTemplateElement ) {
			this.template = template;
		}
	}

	render( values: Record<string, unknown> = {} ) : DocumentFragment {
		if ( !this.template ) {
			return document.createDocumentFragment();
		}

		if ( !this.template?.content ) {
			const fragment = document.createDocumentFragment();

			const childNodes = Array.from( this.template.childNodes );
			childNodes.forEach( ( childNode ) => {
				fragment.appendChild( childNode );
			} );

			this.#fragment = fragment;
		}

		let fragmentCopy: DocumentFragment | null = null;
		if ( this.#fragment ) {
			fragmentCopy = this.#fragment.cloneNode( true ) as DocumentFragment;
		} else if ( this.template.content ) {
			fragmentCopy = this.template.content.cloneNode( true ) as DocumentFragment;
		}

		if ( !fragmentCopy ) {
			return document.createDocumentFragment();
		}

		this.assignValues( fragmentCopy, values );

		return fragmentCopy;
	}

	private assignValues( fragment: DocumentFragment, values: Record<string, unknown> ): void {
		Object.entries( values ).forEach( ( pair ) => {
			const selector = pair[0];
			const value = pair[1];

			try {
				const part = fragment.querySelector( selector );
				if ( !part ) {
					return;
				}

				if ( 'string' === typeof value ) {
					part.textContent = value;

					return;
				}

				if ( value && 'object' === typeof value && !Array.isArray( value ) ) {
					this.assignAttribute( part, value as Record<string, unknown> );

					return;
				}
			} catch {
				console.warn( `Invalid template replacement for selector \`${selector}\`` );
			}
		} );
	}

	private assignAttribute( part: Element, value: Record<string, unknown> ) {
		Object.entries( value ).forEach( ( pair ) => {
			const attribute = pair[0];
			const v = pair[1];

			if ( 'object' === typeof v && !Array.isArray( value ) ) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				Object.assign( ( <any>part )[attribute], v );
			} else {
				Object.assign( part, {
					[attribute]: v,
				} );
			}
		}, {} );
	}
}
